body { background-color: $COL53; }
body { color: $COL2; }
[data-pad="0"] { margin-top: 20px; }

@media #{$large-up} {
[data-pad="0"] { margin-top: 30px; }

}
[data-pad="1"] { margin-top: 5px; }

h1 {
color: $COL18;
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: $COL20;
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
color: $COL22;
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 22px;

@media #{$medium-up} {
font-size: 25px;

}
}
h4 {
color: $COL33;
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1;
font-size: 18px;

@media #{$medium-up} {
font-size: 22px;

}
@media #{$large-up} {
font-size: 45px;

}
}
h5 {
color: $COL42;
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
@media #{$large-up} {
font-size: 35px;

}
}
h6 {
color: $COL28;
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
.button {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 13px;

}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: 600;
font-style: italic;
line-height: 1.5;
font-size: 13px;

}
ol,ul {
color: $COL29;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 13px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: $COL45;
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 20px;

}
@media #{$large-up} {
font-size: 35px;

}
}
summary {
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1;
font-size: 18px;

@media #{$medium-up} {
font-size: 22px;

}
@media #{$large-up} {
font-size: 45px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 13px;

}
}
p { padding-bottom: 1em; }
a {color: $COL7}
/* Primary:2 */
.MES2 {
background-color: $COL4;
color: $COL5;
 }
/* Primary:3 */
.MES3 {
background-color: $COL4;
color: $COL5;
 }
.MES3 {
background-color: $COL4;
color: $COL5;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: $COL5;
 }
 }
cite.MEC3{
color: $COL5;
}
/* Secondary:4 */
.MES4 {
background-color: $COL9;
color: $COL32;
 }
/* Secondary:5 */
.MES5 {
background-color: $COL9;
color: $COL10;
 }
.MES5 {
background-color: $COL9;
color: $COL10;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: $COL10;
 }
 }
cite.MEC5{
color: $COL10;
}
/* Alternate:6 */
.MES6 {
background-color: $COL12;
color: $COL13;
 }
/* Alternate:7 */
.MES7 {
background-color: $COL12;
color: $COL13;
 }
.MES7 {
background-color: $COL12;
color: $COL13;
h1.MEC7, h2.MEC7, h3.MEC7, h4.MEC7, h5.MEC7, h6.MEC7 { color: $COL13;
 }
 }
cite.MEC7{
color: $COL13;
}
/* Light:8 */
.MES8 {
background-color: $COL15;
color: $COL16;
 }
/* Light:9 */
.MES9 {
background-color: $COL15;
color: $COL16;
 }
.MES9 {
background-color: $COL15;
color: $COL16;
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: $COL16;
 }
 }
cite.MEC9{
color: $COL16;
}
/* Main Menu:11 */
nav.me-Menu.MES11 {
.menu-item.MEC11{background-color: $COL15;
 &:hover {background-color: $COL32}
}
& .menu-item.MEC11, & .menu-item.MEC11 > div.MEC11{ & > a.MEC11{color: $COL32;
font-size: 14px;
text-transform: uppercase;
}
 &:hover > a.MEC11{color: $COL39;
}
 }
&.horizontal > .menu-item.MEC11 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC11 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC11 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC11 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 22px 0;}

& .sub-menu .menu-item a{padding: 18px 10px;}


& > .menu-item.MEC11.active { background-color: $COL32;
& > a{ color: $COL39;}
 }
&.horizontal .menu-item.MEC11:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid $COL32;}}
&.vertical .menu-item.MEC11:hover {& > .pointer-wrap > .pointer{border-right: 10px solid $COL32;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC11{background-color: $COL32;
 &:hover {background-color: $COL39}
}
& .menu-item.MEC11, & .menu-item.MEC11 > div.MEC11{ & > a.MEC11{color: $COL39;
font-size: 14px;
@media #{$large-up} {
font-size: 16.1px;
}text-transform: capitalize;
}
 &:hover > a.MEC11{color: $COL32;
}
 }

}
}
 }
/* Service At a Glance:12 */
.MES12 {
background-color: $COL4;
&:hover { background-color: $COL4;}
color: $COL9;
&:hover { color: $COL9;}
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 20px;
};
 }
/* Free Quto Panel:13 */
.MES13 {
background-color: $COL9;
&:hover, &.hover { background-color: $COL9;}
color: $COL4;
font-size: 9.8px;
@media #{$medium-up} {
font-size: 13px;
};
 }
.MES13 {
background-color: $COL9;
&:hover, &.hover { background-color: $COL9;}
color: $COL4;
font-size: 9.8px;
@media #{$medium-up} {
font-size: 13px;
};
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: $COL4;
 }
 }
a.MEC13 { color: $COL4;
&:hover { color: $COL4; }
 }
cite.MEC13{
color: $COL4;
font-size: 9.8px;
@media #{$medium-up} {
font-size: 13px;
};
}
/* Service at a glance:14 */
.MES14 {
background-color: $COL4;
color: $COL9;
padding: 15px;

 }
.MES14 {
background-color: $COL4;
color: $COL9;
padding: 15px;

h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: $COL9;
 }
h1.MEC14 { font-size: 15px; }
h1.MEC14 { @media #{$medium-up} { font-size: 38px; }; }
h2.MEC14 { font-size: 13.2px; }
h2.MEC14 { @media #{$medium-up} { font-size: 33.25px; }; }
h3.MEC14 { font-size: 13.2px; }
h3.MEC14 { @media #{$medium-up} { font-size: 23.75px; }; }
h4.MEC14 { font-size: 10.8px; }
h4.MEC14 { @media #{$medium-up} { font-size: 20.9px; }; }
h5.MEC14 { font-size: 10.2px; }
h5.MEC14 { @media #{$medium-up} { font-size: 19px; }; }
h6.MEC14 { font-size: 10.2px; }
h6.MEC14 { @media #{$medium-up} { font-size: 19px; }; }
 }
a.MEC14 { color: $COL9;
&:hover { color: $COL9; }
 }
cite.MEC14{
color: $COL9;
}
/* Slider Right Panel:15 */
.MES15 {
background-color: $COL32;
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://crewther.com.au/img/3298/186');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL39;
font-size: 14px;
@media #{$medium-up} {
font-size: 13px;
};
@media #{$large-up} {
font-size: 15.6px;
};
padding: 15px;

 }
.MES15 {
background-color: $COL32;
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://crewther.com.au/img/3298/186');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL39;
font-size: 14px;
@media #{$medium-up} {
font-size: 13px;
};
@media #{$large-up} {
font-size: 15.6px;
};
padding: 15px;

 }
cite.MEC15{
color: $COL39;
font-size: 14px;
@media #{$medium-up} {
font-size: 13px;
};
@media #{$large-up} {
font-size: 15.6px;
};
}
/* Banner Slider:16 */
.MES16 {
& .slider-arrow {background-color: $COL34;
color: $COL39;
font-size: 20px;
@media #{$medium-up} {
font-size: 20px;
};
@media #{$large-up} {
font-size: 20px;
};
}& .slick-dots {text-align: center;
 button {margin: 0 5px;
;border-color: $COL35;
border-radius: 50%;
border-style:solid;
border-width:1px;
@media #{$medium-up} {
border-width: 1px;
};
@media #{$large-up} {
border-width: 1px;};
&:hover{border-color: $COL34;
;}
background-color: $COL35;
background-clip: padding-box;
&:hover {background-color: $COL34;}
width:10px;
height:10px;
@media #{$medium-up} {
width:10px;
height:10px;
};
@media #{$large-up} {
width:10px;
height:10px;
};
&:hover{background-color: $COL34;
;}}}
& .slick-dots .slick-active {
  button{
border-color: $COL34;
background-color: $COL34;
  }
}
 }
/* Footer Panel:17 */
.MES17 {
background-color: $COL36;
color: $COL39;
font-size: 11.9px;
@media #{$medium-up} {
font-size: 11.05px;
};
padding: 20px 15px;

border-width: 10px 0 0 0;
border-style: solid;
border-color: $COL37 transparent transparent transparent;
 }
.MES17 {
background-color: $COL36;
color: $COL39;
font-size: 11.9px;
@media #{$medium-up} {
font-size: 11.05px;
};
padding: 20px 15px;

border-width: 10px 0 0 0;
border-style: solid;
border-color: $COL37 transparent transparent transparent;
h1.MEC17, h2.MEC17, h3.MEC17, h4.MEC17, h5.MEC17, h6.MEC17 { color: $COL39;
 }
 }
a.MEC17 { color: $COL39;
&:hover { color: $COL39; }
 }
cite.MEC17{
color: $COL39;
font-size: 11.9px;
@media #{$medium-up} {
font-size: 11.05px;
};
}
/* Footer Menu:18 */
nav.me-Menu.MES18 {
& .menu-item.MEC18, & .menu-item.MEC18 > div.MEC18{ & > a.MEC18{color: $COL39;
font-size: 11.9px;
text-transform: capitalize;
}
 &:hover > a.MEC18{color: $COL39;
}
 }
&.horizontal > .menu-item.MEC18 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC18 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC18 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC18 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 0;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Content:19 */
.MES19 {
background-color: $COL39;
padding: 20px 15px;

@media #{$medium-up} {
padding: 30px;

}
 }
.MES19 {
background-color: $COL39;
padding: 20px 15px;

@media #{$medium-up} {
padding: 30px;

}
 }
/* Header:20 */
.MES20 {
background-color: $COL39;
color: $COL9;
padding: 0 0 5px 0;

 }
.MES20 {
background-color: $COL39;
color: $COL9;
padding: 0 0 5px 0;

 }
cite.MEC20{
color: $COL9;
}
/* Service at a glance sub:22 */
.MES22 {
background-color: $COL4;
color: $COL9;
padding: 0 0 20px 0;

 }
.MES22 {
background-color: $COL4;
color: $COL9;
padding: 0 0 20px 0;

h1.MEC22, h2.MEC22, h3.MEC22, h4.MEC22, h5.MEC22, h6.MEC22 { color: $COL9;
 }
h1.MEC22 { font-size: 15px; }
h2.MEC22 { font-size: 13.2px; }
h3.MEC22 { font-size: 13.2px; }
h4.MEC22 { font-size: 10.8px; }
h5.MEC22 { font-size: 10.2px; }
h6.MEC22 { font-size: 10.2px; }
 }
a.MEC22 { color: $COL9;
&:hover { color: $COL9; }
 }
cite.MEC22{
color: $COL9;
}
/* right menu panel:23 */
.MES23 {
background-color: $COL54;
color: $COL48;
padding: 10px;

 }
.MES23 {
background-color: $COL54;
color: $COL48;
padding: 10px;

h1.MEC23, h2.MEC23, h3.MEC23, h4.MEC23, h5.MEC23, h6.MEC23 { color: $COL48;
 }
 }
a.MEC23 { color: $COL48;
&:hover { color: $COL48; }
 }
cite.MEC23{
color: $COL48;
}
/* right menu divider:24 */
.MES24 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: $COL53 transparent transparent transparent;}
& > hr {border-top-style: dotted;}
 }
/* right menu head:25 */
.MES25 {
background-color: $COL4;
color: $COL5;
padding: 30px;

 }
.MES25 {
background-color: $COL4;
color: $COL5;
padding: 30px;

h1.MEC25, h2.MEC25, h3.MEC25, h4.MEC25, h5.MEC25, h6.MEC25 { color: $COL5;
 }
h1.MEC25 { font-size: 23.75px; }
h2.MEC25 { font-size: 20.9px; }
h3.MEC25 { font-size: 20.9px; }
h4.MEC25 { font-size: 17.1px; }
h5.MEC25 { font-size: 16.15px; }
h6.MEC25 { font-size: 16.15px; }
 }
cite.MEC25{
color: $COL5;
}
/* Service More Button:27 */
.MES27 {
background-color: $COL32;
&:hover { background-color: $COL32;}
color: $COL39;
&:hover { color: $COL39;}
padding: 5px 10px;

 }
/* service blocks panel white:28 */
.MES28 {
background-color: $COL39;
&:hover, &.hover { background-color: $COL39;}
color: $COL47;
font-size: 14px;
@media #{$medium-up} {
font-size: 13px;
};
@media #{$medium-up} {
min-height: 200px;};
@media #{$large-up} {
min-height: 250px;};
padding: 20px 30px;

 }
.MES28 {
background-color: $COL39;
&:hover, &.hover { background-color: $COL39;}
color: $COL47;
font-size: 14px;
@media #{$medium-up} {
font-size: 13px;
};
@media #{$medium-up} {
min-height: 200px;};
@media #{$large-up} {
min-height: 250px;};
padding: 20px 30px;

h1.MEC28, h2.MEC28, h3.MEC28, h4.MEC28, h5.MEC28, h6.MEC28 { color: $COL47;
 }
h1.MEC28 { font-size: 18.75px; }
h2.MEC28 { font-size: 16.5px; }
h3.MEC28 { font-size: 16.5px; }
h4.MEC28 { font-size: 13.5px; }
h5.MEC28 { font-size: 12.75px; }
h6.MEC28 { font-size: 12.75px; }
 }
cite.MEC28{
color: $COL47;
font-size: 14px;
@media #{$medium-up} {
font-size: 13px;
};
}
/* service blocks panel grey:29 */
.MES29 {
background-color: $COL15;
&:hover, &.hover { background-color: $COL15;}
color: $COL47;
font-size: 14px;
@media #{$medium-up} {
font-size: 13px;
};
@media #{$medium-up} {
min-height: 200px;};
@media #{$large-up} {
min-height: 250px;};
padding: 20px 30px;

 }
.MES29 {
background-color: $COL15;
&:hover, &.hover { background-color: $COL15;}
color: $COL47;
font-size: 14px;
@media #{$medium-up} {
font-size: 13px;
};
@media #{$medium-up} {
min-height: 200px;};
@media #{$large-up} {
min-height: 250px;};
padding: 20px 30px;

h1.MEC29, h2.MEC29, h3.MEC29, h4.MEC29, h5.MEC29, h6.MEC29 { color: $COL47;
 }
h1.MEC29 { font-size: 18.75px; }
h2.MEC29 { font-size: 16.5px; }
h3.MEC29 { font-size: 16.5px; }
h4.MEC29 { font-size: 13.5px; }
h5.MEC29 { font-size: 12.75px; }
h6.MEC29 { font-size: 12.75px; }
 }
cite.MEC29{
color: $COL47;
font-size: 14px;
@media #{$medium-up} {
font-size: 13px;
};
}
/* folio panel:30 */
.MES30 {
background-color: $COL39;
 }
.MES30 {
background-color: $COL39;
h1.MEC30, h2.MEC30, h3.MEC30, h4.MEC30, h5.MEC30, h6.MEC30 { color: $COL47;
 }
h1.MEC30 { font-size: 12.5px; }
h2.MEC30 { font-size: 11px; }
h3.MEC30 { font-size: 11px; }
h4.MEC30 { font-size: 9px; }
h5.MEC30 { font-size: 8.5px; }
h6.MEC30 { font-size: 8.5px; }
 }
/* Testim Panel:31 */
.MES31 {
background-color: $COL53;
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 30px 20px;

border-width: 1px;
border-style: solid;
border-color: $COL55;
 }
.MES31 {
background-color: $COL53;
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 30px 20px;

border-width: 1px;
border-style: solid;
border-color: $COL55;
 }
/* Responsive Header:32 */
.MES32 {
background-color: $COL32;
color: $COL39;
font-size: 14px;
@media #{$medium-up} {
font-size: 13px;
};
padding: 10px 0;

 }
.MES32 {
background-color: $COL32;
color: $COL39;
font-size: 14px;
@media #{$medium-up} {
font-size: 13px;
};
padding: 10px 0;

h1.MEC32, h2.MEC32, h3.MEC32, h4.MEC32, h5.MEC32, h6.MEC32 { color: $COL9;
 }
h1.MEC32 { font-size: 20px; }
h1.MEC32 { @media #{$medium-up} { font-size: 40px; }; }
h2.MEC32 { font-size: 17.6px; }
h2.MEC32 { @media #{$medium-up} { font-size: 35px; }; }
h3.MEC32 { font-size: 17.6px; }
h3.MEC32 { @media #{$medium-up} { font-size: 25px; }; }
h4.MEC32 { font-size: 14.4px; }
h4.MEC32 { @media #{$medium-up} { font-size: 22px; }; }
h5.MEC32 { font-size: 13.6px; }
h5.MEC32 { @media #{$medium-up} { font-size: 20px; }; }
h6.MEC32 { font-size: 13.6px; }
h6.MEC32 { @media #{$medium-up} { font-size: 20px; }; }
 }
a.MEC32 { color: $COL9;
&:hover { color: $COL9; }
 }
cite.MEC32{
color: $COL39;
font-size: 14px;
@media #{$medium-up} {
font-size: 13px;
};
}
/* Banner Slider Left Panel:33 */
.MES33 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://crewther.com.au/img/3308/229');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}min-height:154px;
@media #{$medium-up} {
min-height: 200px;};
@media #{$large-up} {
min-height: 400px;};
 }
.MES33 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://crewther.com.au/img/3308/229');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}min-height:154px;
@media #{$medium-up} {
min-height: 200px;};
@media #{$large-up} {
min-height: 400px;};
 }
/* Credit Panel:83 */
.MES83 {
background-color: $COL53;
color: $COL145;
padding: 5px 15px 10px 15px;

 }
.MES83 {
background-color: $COL53;
color: $COL145;
padding: 5px 15px 10px 15px;

 }
cite.MEC83{
color: $COL145;
}
/* Services Menu:91 */
nav.me-Menu.MES91 {
.menu-item.MEC91{background-color: $COL4;
 &:hover {background-color: $COL4}
}
& .menu-item.MEC91, & .menu-item.MEC91 > div.MEC91{ & > a.MEC91{color: $COL9;
font-size: 14px;
text-transform: uppercase;
}
 &:hover > a.MEC91{color: $COL9;
}
 }
&.horizontal > .menu-item.MEC91 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC91 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC91 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC91 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 0 10px 20px 10px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
