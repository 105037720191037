$COL1: #f2f2f2; //
$COLFLAT1: #f2f2f2; //
$COL2: #000000; //
$COLFLAT2: #000000; //
$COL3: #0079c2; //
$COLFLAT3: #0079c2; //
$COL4: #0079c2; //
$COLFLAT4: #0079c2; //
$COL5: #ffffff; //
$COLFLAT5: #ffffff; //
$COL6: #2f9aea; //
$COLFLAT6: #2f9aea; //
$COL7: #2f9aea; //
$COLFLAT7: #2f9aea; //
$COL8: #ffde00; //
$COLFLAT8: #ffde00; //
$COL9: #ffde00; //
$COLFLAT9: #ffde00; //
$COL10: #000000; //
$COLFLAT10: #000000; //
$COL11: #d75759; //
$COLFLAT11: #d75759; //
$COL12: #d75759; //
$COLFLAT12: #d75759; //
$COL13: #ffffff; //
$COLFLAT13: #ffffff; //
$COL14: #dddddd; //
$COLFLAT14: #dddddd; //
$COL15: #dddddd; //
$COLFLAT15: #dddddd; //
$COL16: #000000; //
$COLFLAT16: #000000; //
$COL17: #0091e8; //
$COLFLAT17: #0091e8; //
$COL18: #0091e8; //
$COLFLAT18: #0091e8; //
$COL19: #0091e8; //
$COLFLAT19: #0091e8; //
$COL20: #0091e8; //
$COLFLAT20: #0091e8; //
$COL21: #0091e8; //
$COLFLAT21: #0091e8; //
$COL22: #0091e8; //
$COLFLAT22: #0091e8; //
$COL23: #0091e8; //
$COLFLAT23: #0091e8; //
$COL24: #ffde00; //
$COLFLAT24: #ffde00; //
$COL25: #0091e8; //
$COLFLAT25: #0091e8; //
$COL26: #ffffff; //
$COLFLAT26: #ffffff; //
$COL27: #0091e8; //
$COLFLAT27: #0091e8; //
$COL28: #0091e8; //
$COLFLAT28: #0091e8; //
$COL29: #000000; //
$COLFLAT29: #000000; //
$COL30: rgba(0,0,0,0); //
$COLFLAT30: #808080; //
$COL31: #0091e8; //Menu Blue
$COLFLAT31: #0091e8; //Menu Blue
$COL32: #0091e8; //Menu Blue
$COLFLAT32: #0091e8; //Menu Blue
$COL33: #ffde00; //
$COLFLAT33: #ffde00; //
$COL34: rgba(0,0,0,0.8); //black 80%
$COLFLAT34: #1a1a1a; //black 80%
$COL35: rgba(0,0,0,0.251); //black 25%
$COLFLAT35: #606060; //black 25%
$COL36: #003351; //footer color
$COLFLAT36: #003351; //footer color
$COL37: #cccccc; //grey
$COLFLAT37: #cccccc; //grey
$COL38: #f2f2f2; //
$COLFLAT38: #f2f2f2; //
$COL39: #ffffff; //White
$COLFLAT39: #ffffff; //White
$COL40: #f2f2f2; //
$COLFLAT40: #f2f2f2; //
$COL41: #0079c2; //
$COLFLAT41: #0079c2; //
$COL42: #ffffff; //
$COLFLAT42: #ffffff; //
$COL43: #0079c2; //
$COLFLAT43: #0079c2; //
$COL44: #005b93; //
$COLFLAT44: #005b93; //
$COL45: #005b93; //
$COLFLAT45: #005b93; //
$COL46: rgba(255,255,255,0.8); //white 80%
$COLFLAT46: #e6e6e6; //white 80%
$COL47: #000000; //black
$COLFLAT47: #000000; //black
$COL48: #0066a4; //free quote button
$COLFLAT48: #0066a4; //free quote button
$COL49: #f2f2f2; //
$COLFLAT49: #f2f2f2; //
$COL50: #f2f2f2; //
$COLFLAT50: #f2f2f2; //
$COL51: #f2f2f2; //
$COLFLAT51: #f2f2f2; //
$COL52: #f2f2f2; //
$COLFLAT52: #f2f2f2; //
$COL53: #f2f2f2; //
$COLFLAT53: #f2f2f2; //
$COL54: #bce6ff; //right menu
$COLFLAT54: #bce6ff; //right menu
$COL55: #d8d8d8; //grey
$COLFLAT55: #d8d8d8; //grey
$COL56: rgba(0,0,0,0); //
$COLFLAT56: #808080; //
$COL57: #f2f2f2; //
$COLFLAT57: #f2f2f2; //
$COL58: #f2f2f2; //
$COLFLAT58: #f2f2f2; //
$COL59: #ee3124; //
$COLFLAT59: #ee3124; //
$COL60: #ee3124; //
$COLFLAT60: #ee3124; //
$COL61: #ffffff; //
$COLFLAT61: #ffffff; //
$COL62: #febe10; //
$COLFLAT62: #febe10; //
$COL63: #febe10; //
$COLFLAT63: #febe10; //
$COL64: #0261aa; //
$COLFLAT64: #0261aa; //
$COL65: #ffffff; //
$COLFLAT65: #ffffff; //
$COL66: #f2f2f2; //
$COLFLAT66: #f2f2f2; //
$COL67: #f2f2f2; //
$COLFLAT67: #f2f2f2; //
$COL68: #000000; //
$COLFLAT68: #000000; //
$COL69: #ffffff; //
$COLFLAT69: #ffffff; //
$COL70: rgba(0,0,0,0.98); //
$COLFLAT70: #030303; //
$COL71: #0261aa; //
$COLFLAT71: #0261aa; //
$COL72: #febe10; //
$COLFLAT72: #febe10; //
$COL73: #febe10; //
$COLFLAT73: #febe10; //
$COL74: #febe10; //
$COLFLAT74: #febe10; //
$COL75: #febe10; //
$COLFLAT75: #febe10; //
$COL76: #febe10; //
$COLFLAT76: #febe10; //
$COL77: #febe10; //
$COLFLAT77: #febe10; //
$COL78: #ffffff; //
$COLFLAT78: #ffffff; //
$COL79: #0261aa; //
$COLFLAT79: #0261aa; //
$COL80: #0261aa; //
$COLFLAT80: #0261aa; //
$COL81: #febe10; //
$COLFLAT81: #febe10; //
$COL82: #febe10; //
$COLFLAT82: #febe10; //
$COL83: #febe10; //
$COLFLAT83: #febe10; //
$COL84: #febe10; //
$COLFLAT84: #febe10; //
$COL85: #febe10; //
$COLFLAT85: #febe10; //
$COL86: #febe10; //
$COLFLAT86: #febe10; //
$COL87: #0261aa; //
$COLFLAT87: #0261aa; //
$COL88: #0261aa; //
$COLFLAT88: #0261aa; //
$COL89: #000000; //
$COLFLAT89: #000000; //
$COL90: #000000; //
$COLFLAT90: #000000; //
$COL91: #0261aa; //Menu Blue
$COLFLAT91: #0261aa; //Menu Blue
$COL92: rgba(0,0,0,0.8); //black 80%
$COLFLAT92: #1a1a1a; //black 80%
$COL93: rgba(0,0,0,0.251); //black 25%
$COLFLAT93: #606060; //black 25%
$COL94: #d8d8d8; //grey
$COLFLAT94: #d8d8d8; //grey
$COL95: #000000; //black
$COLFLAT95: #000000; //black
$COL96: #febe10; //
$COLFLAT96: #febe10; //
$COL97: rgba(0,0,0,0); //
$COLFLAT97: #808080; //
$COL98: rgba(255,255,255,0.502); //White 50%
$COLFLAT98: #c0c0c0; //White 50%
$COL99: #f2f2f2; //
$COLFLAT99: #f2f2f2; //
$COL100: #f2f2f2; //
$COLFLAT100: #f2f2f2; //
$COL101: rgba(0,0,0,0); //
$COLFLAT101: #808080; //
$COL102: #aeb73a; //
$COLFLAT102: #aeb73a; //
$COL103: #c8dc45; //
$COLFLAT103: #c8dc45; //
$COL104: #58595b; //
$COLFLAT104: #58595b; //
$COL105: #58595b; //
$COLFLAT105: #58595b; //
$COL106: #58595b; //
$COLFLAT106: #58595b; //
$COL107: #ffffff; //
$COLFLAT107: #ffffff; //
$COL108: #8a930c; //
$COLFLAT108: #8a930c; //
$COL109: #8a930c; //
$COLFLAT109: #8a930c; //
$COL110: #8a930c; //
$COLFLAT110: #8a930c; //
$COL111: #8a930c; //
$COLFLAT111: #8a930c; //
$COL112: #f2f2f2; //
$COLFLAT112: #f2f2f2; //
$COL113: #000000; //
$COLFLAT113: #000000; //
$COL114: #0261aa; //
$COLFLAT114: #0261aa; //
$COL115: #ffffff; //
$COLFLAT115: #ffffff; //
$COL116: #0261aa; //
$COLFLAT116: #0261aa; //
$COL117: rgba(0,0,0,0); //
$COLFLAT117: #808080; //
$COL118: rgba(0,0,0,0); //
$COLFLAT118: #808080; //
$COL119: rgba(0,0,0,0); //
$COLFLAT119: #808080; //
$COL120: rgba(0,0,0,0); //
$COLFLAT120: #808080; //
$COL121: #8a930c; //
$COLFLAT121: #8a930c; //
$COL122: #8a930c; //
$COLFLAT122: #8a930c; //
$COL123: #8a930c; //
$COLFLAT123: #8a930c; //
$COL124: #8a930c; //
$COLFLAT124: #8a930c; //
$COL125: #8a930c; //
$COLFLAT125: #8a930c; //
$COL126: #8a930c; //
$COLFLAT126: #8a930c; //
$COL127: #58595b; //
$COLFLAT127: #58595b; //
$COL128: #58595b; //
$COLFLAT128: #58595b; //
$COL129: #ffffff; //white
$COLFLAT129: #ffffff; //white
$COL130: #807e8a; //
$COLFLAT130: #807e8a; //
$COL131: #58595b; //
$COLFLAT131: #58595b; //
$COL132: #58595b; //
$COLFLAT132: #58595b; //
$COL133: #cccccc; //grey
$COLFLAT133: #cccccc; //grey
$COL134: #0261aa; //banner blue
$COLFLAT134: #0261aa; //banner blue
$COL135: rgba(0,0,0,0.8); //black 80%
$COLFLAT135: #1a1a1a; //black 80%
$COL136: rgba(0,0,0,0.251); //black 25%
$COLFLAT136: #606060; //black 25%
$COL137: #dddddd; //grey
$COLFLAT137: #dddddd; //grey
$COL138: #c8dc45; //
$COLFLAT138: #c8dc45; //
$COL139: #ffffff; //
$COLFLAT139: #ffffff; //
$COL140: #c8dc45; //
$COLFLAT140: #c8dc45; //
$COL141: #000000; //
$COLFLAT141: #000000; //
$COL142: #8a930c; //
$COLFLAT142: #8a930c; //
$COL143: #8a930c; //
$COLFLAT143: #8a930c; //
$COL144: rgba(138,147,12,0.98); //text
$COLFLAT144: #8a930e; //text
$COL145: #999999; //dark grey
$COLFLAT145: #999999; //dark grey
$COL146: #999999; //Dark grey
$COLFLAT146: #999999; //Dark grey
$COL147: #999999; //Dark Grey
$COLFLAT147: #999999; //Dark Grey
$COL148: #222222; //dark
$COLFLAT148: #222222; //dark
$COL149: #333333; //dark 2
$COLFLAT149: #333333; //dark 2
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
